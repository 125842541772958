import { Col, Row } from "../../Grid-system";
import {
  Add,
  DeleteAndEdit,
  Loading,
  Pagination,
  Title,
} from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Titles = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/family-names${
      filter.get("page") ? "?page=" + filter.get("page") : ""
    }`,
    "admin/family-names"
  );
  return (
    <div>
      <Col md={8}>
        <Title className="mb-5" title="الألقاب" />
      </Col>
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          <Col>
            <table className="w-[70%] max-lg:w-full mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-boldBlue">
              <thead className="bg-Blue text-white h-[70px]">
                <tr>
                  <td className="lg:px-5">اسم اللقب</td>
                  <td>العمليات</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e) => (
                    <tr className="border border-boldBlue">
                      <td className="font-bold text-lg">{e.name}</td>
                      <td className="flex justify-center items-center gap-3 mt-2 mb-1">
                        <DeleteAndEdit
                          deleteClick={() => deleteItem(e)}
                          edit={`/titles/${e.id}`}
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <Add link={`/titles/add`} />
    </div>
  );
};

export default Titles;
