import { Col, Row } from "../../Grid-system";
import {
  ButtonRed,
  Loading,
  Pagination,
  Title,
} from "../../components";
import { fileUrl, useFETCH } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
const Occasions = () => {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/users/${id}/occasions`);
  return (
    <div>
      <Col md={2}>
        <Title className="mb-5" title="المناسبات" />
      </Col>
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          <Col className="overflow-x-auto">
            <table className="w-full  mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-boldBlue">
              <thead className="bg-Blue text-white h-[70px]">
                <tr>
                  <td className="px-5">id</td>
                  <td className="px-5">اسم المشترك</td>
                  <td className="px-5">اسم الداعي</td>
                  <td className="px-5">صورة</td>
                  <td className="px-5">اسفل ال qr</td>
                  <td className="px-5">المدعوين</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e) => (
                    <tr className="border border-boldBlue ">
                      <td className="font-bold text-lg">{e.id}</td>
                      <td className="font-semibold py-7">{e.name}</td>
                      <td className="font-semibold py-7">{e.inviter_name}</td>
                      <td className="">
                        <img
                          src={fileUrl + e.image}
                          alt=""
                          className="w-16 h-16 mx-auto rounded-full"
                        />
                      </td>
                      <td className="font-semibold">{e.under_qr}</td>
                      <td className="">
                        <ButtonRed
                          className="py-4 w-fit mx-auto px-6"
                          link={`/users/${id}/occasions/${e.id}`}
                          name="المدعوين"
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
    </div>
  );
};

export default Occasions;
