import React from "react";
import { Back, Input, Loading, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useEffect } from "react";

const EditTitles = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(`admin/family-names/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add" ? "admin/family-names" : `admin/family-names/${id}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataOld?.name,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Blue rounded-2xl p-10 ">
            <Title title="إضافة لقب" className="mb-14 mt-5" />
            <Input
              name="name"
              value={formData?.name}
              onChange={handleChangeInput}
              title="لقب"
            />
          </div>
        </Col>
      </Row>
      {loading ? <Loading /> : ""}
      <div className="text-red-600">{error}</div>
      <Back onClick={handleSubmitMain} name="حفظ" />
    </div>
  );
};

export default EditTitles;
