import { useState } from "react";
import { useCondition } from "../../APIs/useMyAPI";
import { ButtonRed, Buttons, Input, Loading, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useParams } from "react-router-dom";

const InvitationCredit = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState();
  const { handleSubmit, error, loading } = useCondition(
    `admin/users/${id}/manage/balance`
  );
  const handleChangeInput = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(formData);
  };

  return (
    <div className="">
      <Row>
        <Col lg={8} className="mx-auto ">
          <div className="border-2  border-Blue rounded-2xl p-10 ">
            <Title title="تعديل رصيد الدعوات" className="mb-14 mt-5" />
            <div className="flex justify-center items-center gap-10 mb-5 mt-1">
              <div className="flex justify-center items-center gap-2">
                <input
                  type="radio"
                  name="type_guests_balance"
                  value="ADD"
                  onChange={handleChangeInput}
                  className="w-5 h-5"
                />
                <p className="text-xl font-bold max-sm:text-sm">
                  اضف رصيد دعوة
                </p>
              </div>
              <div className="flex justify-center items-center gap-2">
                <input
                  type="radio"
                  name="type_guests_balance"
                  value="REMOVE"
                  onChange={handleChangeInput}
                  className="w-5 h-5"
                />
                <p className="text-xl font-bold max-sm:text-sm">
                  احذف رصيد دعوة
                </p>
              </div>
            </div>
            <Input
              type="number"
              name="guests_balance"
              onChange={handleChangeInput}
              title="أضافة رصيد دعوة"
            />
          </div>
        </Col>
      </Row>
      {loading ? <Loading /> : ""}
      <div className="text-red-600">{error}</div>
      <div className="fixed bottom-10 left-10 flex flex-col gap-2">
        <Buttons name="حفظ" onClick={handleSubmitMain} />
        <ButtonRed className="py-2 px-5" name="إلغاء" link="/users" />
      </div>
    </div>
  );
};

export default InvitationCredit;
