import { Col, Row } from "../../Grid-system";
import {
  Loading,
  Pagination,
  Title,
} from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
const Subscription = () => {
  const { id } = useParams();
      const { filter } = useFilter({});
      const { data, isLoading } = useFETCH(
        `admin/packages/${id}/suscribers${
          filter.get("page") ? "?page=" + filter.get("page") : ""
        }`,
      );
  return (
    <div>
      <Col md={2}>
        <Title className="mb-5" title="المشتركين" />
      </Col>
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          <Col className="overflow-x-scroll">
            <table className="w-full  mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-boldBlue">
              <thead className="bg-Blue text-white h-[70px]">
                <tr>
                  <td className="px-7">اسم المستخدم</td>
                  <td className="px-7">عدد الزوار</td>
                  <td className="px-7">رقم الهاتف</td>
                  <td className="px-7">تاريخ الإشتراك</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e) => (
                    <tr className="border border-boldBlue">
                      <td className="font-bold text-lg">{e.username}</td>
                      <td className="font-semibold py-7">{e.guests_count}</td>
                      <td className="">{e.phone_number}</td>
                      <td className="font-semibold">{e.subcribed_at}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
    </div>
  );
};

export default Subscription;
