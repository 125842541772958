function Input({ title, name, value, type, onChange }) {
  return (
    <div>
      <input
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        className="border border-boldBlue m-1 py-3 rounded-xl"
      />
    </div>
  );
}
export default Input;
