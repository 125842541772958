import React from "react";
import { ButtonRed, Buttons, Input, Loading, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useFETCH, useFilter, usePOST } from "../../APIs/useMyAPI";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const AddUser = () => {
  const { id } = useParams();

  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(`admin/users/${id}`);

  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        username: dataOld?.username,
        phone_number: dataOld?.phone_number,
        updated_at: dataOld?.updated_at,
        guests_balance: dataOld?.guests_balance,
        is_vip: dataOld?.is_vip,
      });
  }, [dataOld]);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "admin/users" : `admin/users/${id}`);
  };
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Blue rounded-2xl p-10 ">
            <Title title="أضف مستخدم" className="mb-14 mt-5" />
            <Input
              name="username"
              value={formData?.username}
              onChange={handleChangeInput}
              title="اسم المستخدم"
            />
            <Input
              name="phone_number"
              value={formData?.phone_number}
              onChange={handleChangeInput}
              title="الرقم"
            />
            <Input
              name="guests_balance"
              value={formData?.guests_balance}
              onChange={handleChangeInput}
              title="guests_balance"
            />
            <div
              defaultValue={dataOld?.is_vip}
              className="flex justify-center items-center gap-10 my-5"
            >
              <div className="flex justify-center items-center gap-2">
                <input
                  type="radio"
                  name="is_vip"
                  value={1}
                  onChange={handleChangeInput}
                  className="w-5 h-5"
                />
                <p className="text-xl font-bold">VIP</p>
              </div>
              <div className="flex justify-center items-center gap-2">
                <input
                  type="radio"
                  name="is_vip"
                  value={0}
                  onChange={handleChangeInput}
                  className="w-5 h-5"
                />
                <p className="text-xl font-bold">Not VIP</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {loading ? <Loading /> : ""}
      <div className="text-red-600">{error}</div>
      <div className="fixed bottom-10 left-10 flex flex-col gap-2">
        <Buttons name="حفظ" onClick={handleSubmitMain} />
        <ButtonRed className="py-2 px-5" name="إلغاء" link="/users" />
      </div>
    </div>
  );
};

export default AddUser;
