import { Col, Row } from "../../Grid-system";
import {
  Add,
  ButtonRed,
  DeleteAndEdit,
  Loading,
  Pagination,
  Title,
} from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Users = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users${filter.get("page") ? "?page=" + filter.get("page") : ""}`,
    "admin/users"
  );
  return (
    <div>
      <Col md={4}>
        <Title className="mb-5" title="المستخدمين" />
      </Col>
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          <Col className="overflow-y-scroll">
            <table className="w-full mx-auto my-5 text-center rounded-2xl overflow-hidden border border-boldBlue">
              <thead className="bg-Blue text-white h-[70px]">
                <tr>
                  <td className="px-5">ID</td>
                  <td className="px-5">الرقم</td>
                  <td className="px-5">Is VIP</td>
                  <td className="px-5">اسم المشترك</td>
                  <td className="px-5">رصيد دعوات</td>
                  <td className="px-5">تاريخ الإنشاء</td>
                  <td>العمليات</td>
                  <td></td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e) => (
                    <tr className="border border-boldBlue">
                      <td className="font-bold text-lg">{e.id}</td>
                      <td className="font-semibold py-7">{e.phone_number}</td>
                      <td className="">{`${e.is_vip ? "نعم" : "لا"}`}</td>
                      <td className="">{e.username}</td>
                      <td className="">{e.guests_balance}</td>
                      <td className="font-semibold">{e.updated_at}</td>
                      <td className="flex justify-center items-center gap-2 mt-2 px-3">
                        <DeleteAndEdit
                          deleteClick={() => deleteItem(e)}
                          edit={`/users/${e.id}`}
                        />
                        <ButtonRed
                          className="py-4 w-[140px] mx-auto"
                          link={`/users/add/${e.id}`}
                          name="رصيد الدعوات"
                        />
                        {/* </td>
                      <td className=""> */}
                        <ButtonRed
                          className="py-4 w-fit mx-auto px-6"
                          link={`/users/${e.id}/occasions`}
                          name="المناسبات"
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <Add link={`/users/add`} />
    </div>
  );
};

export default Users;
