import { Col } from "../../Grid-system";
import { CardBox, Loading, Title } from "../../components";
import { useFETCH } from "../../APIs/useMyAPI";

const FreeInvitations = () => {
  const { data, isLoading } = useFETCH(
    "admin/site-info/free-invitations-count"
  );

  return (
    <div>
      <Col md={8}>
        <Title className="mb-5" title="الباقات المجانية" />
      </Col>
      {isLoading ? <Loading /> : ""}
      <Col md={6} className="mx-auto">
        <CardBox deleted edit="/free-invitations/edit">
          <div className="flex justify-center items-center gap-3">
            <p className="font-bold text-xl">عدد الباقات المجانية : </p>
            <p>{data?.data.data?.["free-invitations-count"]}</p>
          </div>
        </CardBox>
      </Col>
    </div>
  );
};

export default FreeInvitations;
