import Logo from "../../images/logo.png";
import { ButtonRed, Loading } from "../../components";
import { BiHide, BiShow } from "react-icons/bi";
import { useState } from "react";
import { useLOGIN } from "../../APIs/useMyAPI";

const Login = () => {
  const [biShow, setBiShow] = useState(false);
  const { handleSubmit, error, loading, handleChange } = useLOGIN();
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/login");
  };
  return (
    <div className="fixed w-full h-full bg-white top-0 left-0 z-50 flex items-center justify-center text-center">
      <div className="w-1/2 max-lg:w-[90%] flex flex-col justify-center space-y-6 border border-Brown p-10 rounded-2xl">
        <div className="flex justify-center">
          <img src={Logo} alt="" className="w-[300px] mx-auto" />
        </div>
        <div className="flex w-full mx-3">
          <input
            type="email"
            name="email"
            onChange={handleChange}
            placeholder="الأيميل"
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none px-5"
          />
        </div>
        <div className="flex w-full mx-3 relative">
          <input
            type={!biShow ? "password" : "text"}
            onChange={handleChange}
            name="password"
            placeholder="كلمة السر"
            className="block max-md:w-full mx-auto w-[100%] border-b border-b-Brown py-3 pr-5 font-semibold outline-none px-5"
          />
          {biShow ? (
            <BiShow
              onClick={() => setBiShow(false)}
              size={25}
              className="absolute left-3 top-4 cursor-pointer"
            />
          ) : (
            <BiHide
              onClick={() => setBiShow(true)}
              size={25}
              className="absolute left-3 top-4 cursor-pointer"
            />
          )}
        </div>
        {loading ? <Loading /> : ""}
        <div className="text-red-600">{error}</div>
        <div className="w-fit mx-auto">
          <ButtonRed
            name="أرسل"
            onClick={handleSubmitMain}
            className="lg:px-52 max-lg:w-[70vw] py-3 "
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
