import React from "react";
import { Back, Input, Loading, Title } from "../../components";
import { Col, Row } from "../../Grid-system";
import { useParams } from "react-router-dom";
import { useFETCH, usePOST } from "../../APIs/useMyAPI";
import { useEffect } from "react";

const FreeInvitationsEdit = () => {
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(`admin/site-info/free-invitations-count`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(`admin/site-info/free-invitations-count`);
  };
    let dataOld = data?.data.data;
    console.log(dataOld);
  useEffect(() => {
      setFormData({
        free_invitations_count: dataOld?.["free-invitations-count"],
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row>
        <Col md={7} className="mx-auto ">
          <div className="border-2  border-Blue rounded-2xl p-10 ">
            <Title title="تعديل عدد الباقات المجانية" className="mb-14 mt-5" />
            <Input
              name="free_invitations_count"
              value={formData?.free_invitations_count}
              onChange={handleChangeInput}
              title="عدد الباقات المجانية"
            />
          </div>
        </Col>
      </Row>
      {loading ? <Loading /> : ""}
      <div className="text-red-600">{error}</div>
      <Back onClick={handleSubmitMain} name="حفظ" />
    </div>
  );
};

export default FreeInvitationsEdit;
