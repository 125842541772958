import React from "react";
import ButtonRed from "./ButtonRed";
import Buttons from "./Buttons";
import { Link } from "react-router-dom";

const Back = ({ name, onClick, link }) => {
  return (
    <div className="fixed bottom-10 left-10 flex flex-col gap-2">
      <Buttons
        onClick={onClick}
        name={name}
        className={`bg-Purple ${name ? "" : "hidden"}`}
      />
      <Link to={link || ""}>
        <ButtonRed
          className="py-2 px-5"
          name="إلغاء"
          onClick={() => window.history.go(-1)}
        />
      </Link>
    </div>
  );
};

export default Back;
