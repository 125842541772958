import { Col, Row } from "../../Grid-system";
import {
  Add,
  ButtonRed,
  DeleteAndEdit,
  Loading,
  Pagination,
  Title,
} from "../../components";
import { useFETCH, useFilter } from "../../APIs/useMyAPI";

const Payment = () => {
  const { filter } = useFilter({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/packages${filter.get("page") ? "?page=" + filter.get("page") : ""}`,
    "admin/packages"
  );

  return (
    <div>
      <Col md={8}>
        <Title className="mb-5" title="باقات الأشتراك" />
      </Col>
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row>
          <Col className="overflow-x-scroll">
            <table className="w-full max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-boldBlue">
              <thead className="bg-Blue text-white h-[70px]">
                <tr>
                  <td className="px-7">ID</td>
                  <td className="px-7">اسم الباقة</td>
                  <td className="px-7">عدد المدعوين</td>
                  <td className="px-7">السعر</td>
                  <td className="px-7">العمليات</td>
                  <td className="px-7"></td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e) => (
                    <tr className="border border-boldBlue">
                      <td className="font-bold text-lg">{e.id}</td>
                      <td className="font-semibold py-7">{e.name}</td>
                      <td className="">{e.guests_count}</td>
                      <td className="">{e.price}</td>
                      <td className="flex justify-center items-center gap-3 mt-2">
                        <DeleteAndEdit
                          deleteClick={() => deleteItem(e)}
                          edit={`/packages/${e.id}`}
                        />
                      </td>
                      <td className="">
                        <ButtonRed
                          className="py-4 w-fit mx-auto px-6"
                          link={`/packages/${e.id}/subscriber`}
                          name="المشتركين"
                        />
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <Add link={`/packages/add`} />
    </div>
  );
};

export default Payment;
