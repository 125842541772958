import {
  AddUser,
  ChangePassword,
  Login,
  Users,
  Payment,
  Titles,
  Occasions,
  Invitees,
  EditTitles,
  AddPayment,
  Subscription,
  FreeInvitations,
  FreeInvitationsEdit,
  InvitationCredit,
} from "./pages/index";
import { Navbar, SideBar } from "./components/index";
import { Route, Routes } from "react-router-dom";
import Logo from "./images/logo.png";
import { RequireAuth } from "./APIs/useMyAPI";
import { Container } from "./Grid-system";

const App = () => {
  return (
    <>
      <div className="relative flex">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <Container>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route element={<RequireAuth />}>
                <Route
                  path=""
                  element={
                    <h1 className="grid place-content-center h-[80vh]">
                      <img src={Logo} alt="" className="w-[250px]" />
                    </h1>
                  }
                />
                <Route
                  path="*"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      404 | no page found
                    </h1>
                  }
                />
                <Route
                  path="403"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      403 | FORBIDDEN
                    </h1>
                  }
                />
                <Route
                  path="500"
                  element={
                    <h1 className="grid place-content-center h-[80vh] text-Brown font-semibold ">
                      500 | Internal Server Error
                    </h1>
                  }
                />
                <Route path="users">
                  <Route index element={<Users />} />
                  <Route path=":id" element={<AddUser />} />
                  <Route path=":id/occasions" element={<Occasions />} />
                  <Route
                    path="add/:id"
                    element={<InvitationCredit />}
                  />
                  <Route
                    path=":id/occasions/:occasion"
                    element={<Invitees />}
                  />
                </Route>
                <Route path="packages">
                  <Route index element={<Payment />} />
                  <Route path=":id" element={<AddPayment />} />
                  <Route path=":id/subscriber" element={<Subscription />} />
                </Route>
                <Route path="titles">
                  <Route index element={<Titles />} />
                  <Route path=":id" element={<EditTitles />} />
                </Route>
                <Route path="free-invitations">
                  <Route index element={<FreeInvitations />} />
                  <Route path="edit" element={<FreeInvitationsEdit />} />
                </Route>
                <Route path="Change-Password" element={<ChangePassword />} />
              </Route>
            </Routes>
          </Container>
        </div>
      </div>
    </>
  );
};

export default App;
