import React from "react";
import { Link } from "react-router-dom";

const ButtonRed = ({ name, link, onClick, className }) => {
  return (
    <Link to={`${link || ""}`} onClick={onClick}>
      <div
        className={`bg-Blue rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer ${className}`}
      >
        {name}
      </div>
    </Link>
  );
};

export default ButtonRed;
